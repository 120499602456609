/* eslint-disable @typescript-eslint/no-unused-vars */

import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ResetPasswordAPI } from '../../../api/auth.api'
import './index.scss'
import {
  CButton,
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CLoadingButton,
} from '@coreui/react-pro'
import resetPasswordCheck from './formCheck'
import Password from '../../../components/password'
import { useFormik } from 'formik'

const ResetPassword = () => {
  const params = useParams()
  const navigate = useNavigate()
  // const [errors, setErrors] = useState<ErrorsState>(initialErrorsData)
  const [validated, setValidated] = useState<boolean>(false)
  const [successResponse, setsuccessResponse] = useState<boolean>(false)
  const [addResetData, setAddResetData] = useState<AddUserDataType>(initialAddUserData)
  const [id, passwordToken, type]: string[] | undefined | any = Object.values(params)[0]?.split('/')
  const [iconShow, setIconShow] = useState<boolean>(false)
  const [iconShowConfirm, setIconShowConfirm] = useState<boolean>(false)

  const { mutate, isLoading } = useMutation(ResetPasswordAPI, {
    onSuccess: (data) => {
      setsuccessResponse(true)
    },
    onError: (data: any) => {
      const ErrMsg = data?.data?.message
      console.log(ErrMsg, 'error')
    },
  })

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = _.get(e, 'target.value')
    const id = _.get(e, 'target.id')

    if (value !== ' ') {
      if (value.length === 16) {
        return null
      } else {
        setFieldValue(id, value)
      }
    }
  }

  const handleCloseModal = () => {
    navigate('/signin')
  }

  const iconHideShow = () => {
    setIconShow((prevState) => {
      return !prevState
    })
  }
  const iconHideShowConfirm = () => {
    setIconShowConfirm((prevState) => {
      return !prevState
    })
  }

  //formik validation
  const { handleSubmit, values, handleChange, handleBlur, errors, touched, setFieldValue } =
    useFormik({
      validationSchema: resetPasswordCheck,
      initialValues: {
        confirmPassword: '',
        password: '',
      },
      enableReinitialize: true,
      onSubmit: async (values) => {
        // eslint-disable-next-line no-restricted-globals
        event?.preventDefault()

        mutate({
          ...addResetData,
          confirmPassword: values?.confirmPassword,
          password: values?.password,
          id,
          passwordToken,
          type,
        })
      },
    })

  return (
    <>
      {successResponse ? (
        <div className="headerTop">
          <CCard className="add-reset-page mt-4">
            <CCardBody>
              <div className="heading">Password Reset successfully</div>

              <CForm noValidate>
                <CImage
                  align="center"
                  src={'/verifyEmail.png'}
                  width={100}
                  height={100}
                  className="profile-picture"
                />
              </CForm>
            </CCardBody>
            <div className="reset-user-action-buttons ">
              <CButton onClick={() => navigate('/signin')}>Sign In</CButton>
            </div>
          </CCard>
        </div>
      ) : (
        <div className="headerTop">
          <CCard className="add-reset-page mt-4">
            <CCardBody>
              <div className="heading">Reset Password</div>

              <CForm noValidate onSubmit={handleSubmit} className="needs-validation">
                <CInputGroup className="">
                  <CFormInput
                    onPaste={(e: any) => {
                      e.preventDefault()
                      return false
                    }}
                    type={iconShow ? 'text' : 'password'}
                    maxLength={16}
                    placeholder="New Password"
                    autoComplete="password"
                    id="password"
                    value={values.password}
                    invalid={touched.password && Boolean(errors.password)}
                    onChange={(e) => handleOnChange(e)}
                    disabled={isLoading}
                    onBlur={handleBlur}
                  />
                  <Password iconHideShow={iconHideShow} iconShow={iconShow} />
                </CInputGroup>
                <p className="text-danger f-25">
                  {touched.password && Boolean(errors.password) && errors.password}
                </p>

                <CInputGroup className="">
                  <CFormInput
                    onPaste={(e: any) => {
                      e.preventDefault()
                      return false
                    }}
                    type={iconShowConfirm ? 'text' : 'password'}
                    maxLength={16}
                    placeholder="Confirm Password"
                    autoComplete="confirmPassword"
                    id="confirmPassword"
                    disabled={isLoading}
                    value={values.confirmPassword}
                    invalid={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    onChange={(e) => handleOnChange(e)}
                    onBlur={handleBlur}
                  />
                  <Password iconHideShow={iconHideShowConfirm} iconShow2={iconShowConfirm} />
                </CInputGroup>
                <p className="text-danger f-25">
                  {touched.confirmPassword &&
                    Boolean(errors.confirmPassword) &&
                    errors.confirmPassword}
                </p>

                <div className="reset-user-action-buttons">
                  <CLoadingButton type="submit" disabled={isLoading}>
                    Submit
                  </CLoadingButton>
                </div>
              </CForm>
            </CCardBody>
          </CCard>
        </div>
      )}
    </>
  )
}

export default ResetPassword

const initialAddUserData: AddUserDataType = {
  password: '',
  confirmPassword: '',
}

const initialErrorsData: ErrorsState = {}
export interface AddUserDataType {
  password: string
  confirmPassword: string
}

export interface ErrorsState {
  [id: string]: string
}
