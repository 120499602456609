import { useMutation } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EmailVerificationAPI } from '../../../api/auth.api'
import './index.scss'
import { CCard, CCardBody, CForm, CImage } from '@coreui/react-pro'

const VerifyEmail = () => {
  const params = useParams()
  const [validate, setvalidate] = useState<boolean>(false)
  const [id, emailToken, type]: string[] | undefined | any = Object.values(params)[0]?.split('/')
  const [msg, setMsg] = useState('')
  const { mutate } = useMutation(EmailVerificationAPI, {
    onSuccess: (data) => {
      if (data.data.success) {
        toast.success(data?.data?.message)
        setvalidate(true)
        // navigate('/signin')
      } else {
        console.log(' error')
      }
    },
    onError: (data: any) => {
      console.log('setmsg', data.data.success)
      setMsg(data?.data?.message)
    },
  })

  useEffect(() => {
    mutate({ values: { id, emailToken }, type })
  }, [])

  return (
    <>
      {validate ? (
        <div className="headerTop">
          <CCard className="add-verify-email-page mt-4">
            <CCardBody>
              <CImage
                align="center"
                alt="logo"
                src={'/logo.png'}
                width={100}
                height={100}
                className="profile-picture"
              />
              <div className="heading mt-3">Email Verified Successfully</div>

              <CForm noValidate>
                <CImage
                  align="center"
                  src={'/verifyEmail.png'}
                  width={100}
                  height={100}
                  className="profile-picture"
                />
              </CForm>
            </CCardBody>
          </CCard>
        </div>
      ) : (
        <div className="verify-email">
          <div className="m-auto center-loader">
            <h3 className="m-auto"> {msg} </h3>
            {/* <h3 className="m-auto"> Please wait for some time. </h3> */}
            {/* <Loader /> */}
          </div>
        </div>
      )}
    </>
  )
}

export default VerifyEmail
