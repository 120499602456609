import React from 'react'
import { CInputGroupText } from '@coreui/react-pro'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'

type Props = {
  iconHideShow: () => void
  iconShow?: boolean
  iconShow1?: boolean
  iconShow2?: boolean
}
const Password = ({ iconHideShow, iconShow, iconShow1, iconShow2 }: Props) => {
  return (
    <CInputGroupText onClick={iconHideShow}>
      {iconShow || iconShow1 || iconShow2 ? <AiFillEye /> : <AiFillEyeInvisible />}
    </CInputGroupText>
  )
}

export default Password
