import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"

export interface UserDetails {
  contactNumber: string
  createdAt: string
  email: string
  id: string
  isActive: boolean
  name: string
  profilePicture: string
  updatedAt: string
  countryPrefix: string
  adminType: "Super" | "CustomerCare"
}
export interface initialAppState {
  userDetails: UserDetails | undefined | any
  isLoading?: boolean
}
const initialState: initialAppState = {
  userDetails: undefined,
  isLoading: false,
}

export const signedInUserDetailsSlice = createSlice({
  name: "signedInUserDetails",
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
  },
})

export const { setUserDetails } = signedInUserDetailsSlice.actions

export const signedInUserDetailsState = (state: RootState) => state.signedInUserDetails

export default signedInUserDetailsSlice.reducer
