import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface initialAppState {
  sidebarShow: boolean
  theme: string
}

const initialState: initialAppState = {
  sidebarShow: true,
  theme: localStorage.getItem('theme') || 'light',
}

export const appSlice = createSlice({
  name: 'app',
  initialState,

  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSideBarVisibility: (state, action) => {
      state.sidebarShow = action.payload
    },

    setTheme: (state, action) => {
      state.theme = action.payload
    },
  },
})

export const { setSideBarVisibility, setTheme } = appSlice.actions

export const appState = (state: RootState) => state.app

export default appSlice.reducer
