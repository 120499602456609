import React, { useEffect } from 'react';

const DeepLinkHandler = () => {
  const handleDeepLink = () => {
    const isAndroid = /Android/.test(navigator.userAgent);

    if (isAndroid) {
      const packageName = 'com.beautybook.customer';

      const playStoreLink = `market://details?id=${packageName}`;

      window.location.href = playStoreLink;
    } else {
      const appleId = '6450213994';

      const appStoreLink = `itms-apps://itunes.apple.com/app/id${appleId}`;

      window.location.href = appStoreLink;
    }
  };

  useEffect(() => {
    handleDeepLink();
  }, []);

  return <></>;
};

export default DeepLinkHandler;
