import request from '../config/request'
import { requestForEmail } from './../config/request'

const prefix = '/auth'

export const AdminSignInAPI = async (body: any) => {
  const response = await request({
    url: prefix + '/signin',
    method: 'POST',
    body,
  })

  return response
}

export const AdminOtpVerificationAPI = async (body: any) => {
  const response = await request({
    url: prefix + '/otp-verification',
    method: 'POST',
    body,
  })

  return response
}

export const AdminResendOTP = async (body: any) => {
  const response = await request({
    url: prefix + '/resend-otp',
    method: 'POST',
    body,
  })

  return response
}

export const ResendEmailVerificationAPI = async (body: any) => {
  const response = await request({
    url: prefix + '/resend-email-verification-email',
    method: 'POST',
    body,
  })
  return response
}

export const EmailVerificationAPI = async (body: any) => {
  const response = await requestForEmail({
    url: `/verify-email?type=${body?.type}`,
    method: 'PUT',
    // url: prefix + '/email-verification', // old url
    // method: 'POST',
    body: body?.values,
  })
  return response
}

export const ForgotPasswordAPI = async (body: any) => {
  const response = await request({
    url: prefix + '/forgot-password',
    method: 'POST',
    body,
  })

  return response
}
export const ResetPasswordAPI = async (body: any) => {
  const response = await request({
    url: prefix + `/forgot-password-verification?type=${body?.type}`,
    method: 'POST',
    body,
  })

  return response
}
