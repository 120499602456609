import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { validatePayment } from '../../api/common.api';
import { AxiosResponse } from 'axios';

declare global {
  interface Window {
    ReactNativeWebView?: any;
    webkit?: any;
    wpwlOptions?: any;
  }
}

interface ResponseData {
  message: string;
  code: string;
  description: string;
  hyperpaySucess: boolean;
  success: boolean;
}

interface IApiResponse {
  data: ResponseData;
}

const PaymentCallBack = () => {
  const [searchParams] = useSearchParams();

  const { mutate: mutateValidatePayment, isLoading } = useMutation(validatePayment);

  // TODO add vaidite paymnet

  useEffect(() => {
    try {
      const CheckOutId: string = searchParams.get('id') ?? '';

      const postData = {
        type: 'payment',
        payload: { id: CheckOutId, success: true },
      };

      mutateValidatePayment(
        {
          checkoutId: CheckOutId,
        },
        {
          onSuccess: (res: IApiResponse) => {
            if (res.data.hyperpaySucess) {
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView?.postMessage(JSON.stringify(postData));
                return;
              }

              if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.ReactNativeWebView
              ) {
                window.webkit.messageHandlers.ReactNativeWebView.postMessage(
                  JSON.stringify(postData),
                );
                return;
              }
            } else {
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView?.postMessage(
                  JSON.stringify({
                    success: false,
                    Description: String(res.data.description),
                  }),
                );

                return;
              }

              if (
                window.webkit &&
                window.webkit.messageHandlers &&
                window.webkit.messageHandlers.ReactNativeWebView
              ) {
                window.webkit.messageHandlers.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    success: false,
                    Description: String(res.data.description),
                  }),
                );

                return;
              }
            }
          },

          onError: (err) => {
            window.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'payment',
                payload: {
                  id: CheckOutId,
                  success: false,
                  error: err,
                },
              }),
            );
          },
        },
      );
    } catch (e) {
      console.error('react app', e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
      }}
    >
      <h3>{'loading ...'}</h3>
    </div>
  ) : (
    <div />
  );
};

export default PaymentCallBack;
