import { configureStore } from '@reduxjs/toolkit'
import appReducer from './slice/AppSlice'
import signedInUserDetailsReducer from './slice/SignedInUserDetailsSlice'

const store = configureStore({
  reducer: {
    app: appReducer,
    signedInUserDetails: signedInUserDetailsReducer,
  },
})

export default store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
