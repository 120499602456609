/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useMutation } from '@tanstack/react-query'
import _ from 'lodash'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ResendEmailVerificationAPI } from '../../../api/auth.api'
import './index.scss'
import { CCard, CCardBody, CForm, CImage, CLoadingButton } from '@coreui/react-pro'
import { toast } from 'react-toastify'

const VerificationEmail = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [emailSend, setemailSend] = useState<boolean>(false)

  const { mutate } = useMutation(ResendEmailVerificationAPI, {
    onSuccess: (data) => {
      if (data.data.success) {
        toast.success(data?.data?.message)
        setemailSend(true)
      } else {
        console.log(' error')
      }
    },
  })

  const submitClicked = () => {
    if (emailSend) {
      navigate('/signin')
    } else {
      mutate(location?.state)
    }
  }

  return (
    <div className="headerTop">
      <CCard className="add-verification-email-page mt-4">
        <CCardBody>
          <div className="heading">{!emailSend ? 'Verify Email' : 'Email Sent'}</div>
          <h6 className="text-center">
            {!emailSend
              ? 'Check your Email & Click the Resend Email Button To Activate Your Account.'
              : 'Email has been sent'}
          </h6>

          <CForm noValidate>
            <CImage
              align="center"
              src={'/reset.png'}
              width={100}
              height={100}
              className="profile-picture"
            />
            <div className="edit-user-action-buttons ">
              <CLoadingButton onClick={submitClicked}>
                {!emailSend ? 'Resend Email' : 'Sign In'}
              </CLoadingButton>
            </div>
          </CForm>
        </CCardBody>
      </CCard>
    </div>
  )
}

export default VerificationEmail
