// import { HashRouter } from 'react-router-dom'
// import UnsupportedScreen from './containers/UnsupportedScreen'
// import UseWindowDimensions from './hooks/useWindowDimensions'
import { useTypedSelector } from './redux/hooks';
import { appState } from './redux/slice/AppSlice';
import IndexRouter from './routes/IndexRouter';
import { useEffect } from 'react';

const App = () => {
  useEffect(() => {
    const handlePasteAnywhere = (event: any) => {
      navigator?.clipboard?.writeText(window?.getSelection()?.toString()?.trim() || ' ');
    };
    window?.addEventListener('copy', handlePasteAnywhere);
    return () => {
      window?.removeEventListener('copy', handlePasteAnywhere);
    };
  }, []);
  const { theme } = useTypedSelector(appState);
  // const { width } = UseWindowDimensions()

  theme === 'dark'
    ? document.body.classList.add('dark-theme')
    : document.body.classList.remove('dark-theme');

  // return width >= 1280 ? (
  //   <HashRouter>
  //     <IndexRouter />
  //   </HashRouter>
  // ) : (
  //   <UnsupportedScreen />
  // )

  return (
    <div className="app">
      <IndexRouter />
    </div>
  );
};

export default App;
