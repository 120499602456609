import { useLayoutEffect, useState } from 'react';
import env from '../../env';

let RenderWidget = false;

type window = Window &
  typeof globalThis & {
    wpwlOptions: any;
  };

export default function CheckOutPayment({
  baseUrl,
  local,
  checkoutId,
  paymentMehtodSelected,
  appendId,
  invoiceTotalAmount,
}: {
  baseUrl: string;
  local: string;
  checkoutId: string;
  paymentMehtodSelected: any;
  appendId: string;
  invoiceTotalAmount: string;
}) {
  const [loading, setLoading] = useState<boolean>(false);

  useLayoutEffect(() => {
    setLoading(true);

    if (!RenderWidget && !!checkoutId) {
      RenderWidget = true;

      (window as window).wpwlOptions = {
        paymentTarget: '_top',
        registrations: { requireCvv: true },
        applePay: {
          version: 14,
          displayName: 'BBOOKSalon',
          merchantIdentifier: 'merchant.com.beautybook.customer.sa',
          total: {
            label: 'BBOOKSalon',
            amount: parseFloat(invoiceTotalAmount).toFixed(2),
          },
          merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
          supportedNetworks: ['mada', 'masterCard', 'visa'],
          countryCode: 'SA',
          style: 'black',

          onPaymentMethodSelected: function (paymentMethod: any) {
            return {
              newTotal: {
                label: 'BBOOKSalon',
                amount: parseFloat(invoiceTotalAmount).toFixed(2),
              },
            };
          },
        },

        numberFormatting: local === 'ar' ? false : true,
        validation: true,
        locale: local || 'ar',
      };

      const script: HTMLScriptElement = document.createElement('script');
      const div = document.getElementById(appendId);

      script.src = `${env.app.hyper_pay_widget}/v1/paymentWidgets.js?checkoutId=${checkoutId}`;

      script.async = true;

      document.body.appendChild<HTMLScriptElement>(script);

      const form: HTMLFormElement = document.createElement('form');

      const redirectUrl = `${baseUrl}checkPaymentStatus`;

      form.action = redirectUrl;

      form.setAttribute('class', 'paymentWidgets');
      form.setAttribute('data-brands', `${paymentMehtodSelected}`);

      div?.appendChild(form);

      setLoading(false);

      return () => {
        document.body.removeChild<HTMLScriptElement>(script);
        RenderWidget = false;
      };
    }
  }, [appendId, checkoutId, invoiceTotalAmount, paymentMehtodSelected]);

  if (!loading) return null;
  else return <h3>{'Loading ...'}</h3>;
}
