import { object, string, ref } from 'yup'
const resetPasswordCheck = object().shape({
  password: string()
    .required('Please enter new password.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Password must contain at least 8 characters, one uppercase, one number and one special characters.',
    ),
  confirmPassword: string()
    .required('Please enter confirm password.')
    .oneOf([ref('password')], 'New password and confirm password does not match.'),
})

export default resetPasswordCheck
